import * as _borsh2 from "borsh";
var _borsh = _borsh2;
try {
  if ("default" in _borsh2) _borsh = _borsh2.default;
} catch (e) {}
import * as _utils2 from "@near-js/utils";
var _utils = _utils2;
try {
  if ("default" in _utils2) _utils = _utils2.default;
} catch (e) {}
var exports = {};
Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.base_decode = exports.base_encode = exports.deserialize = exports.serialize = void 0;
var borsh_1 = _borsh;
Object.defineProperty(exports, "serialize", {
  enumerable: true,
  get: function () {
    return borsh_1.serialize;
  }
});
Object.defineProperty(exports, "deserialize", {
  enumerable: true,
  get: function () {
    return borsh_1.deserialize;
  }
});
var utils_1 = _utils;
Object.defineProperty(exports, "base_encode", {
  enumerable: true,
  get: function () {
    return utils_1.baseEncode;
  }
});
Object.defineProperty(exports, "base_decode", {
  enumerable: true,
  get: function () {
    return utils_1.baseDecode;
  }
});
export default exports;
export const __esModule = exports.__esModule,
  base_decode = exports.base_decode,
  base_encode = exports.base_encode,
  deserialize = exports.deserialize,
  serialize = exports.serialize;